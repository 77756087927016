import React from "react";
import Modal from 'react-modal';
import Spinner from "../common/Spinner";
import {Heading, suomifiDesignTokens as tokens} from "suomifi-ui-components";

interface Props {
  isOpen: boolean,
  contentLabel?: string,
  message?: string,
  overLay?: number|string,
}

const LoadingModal: React.FC<Props> = (props: Props) => {
  const defaultOverLay = props.overLay ?? 30;
  return (
    <Modal isOpen={props.isOpen} style={{
      content: {
        top: '20%',
        margin: '0 auto',
        width: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: tokens.spacing.xl,
        border: `1px solid ${tokens.colors.depthLight3}`,
        borderRadius: "2px",
      },
      overlay: {
        zIndex: defaultOverLay
      }
    }} contentLabel={props.contentLabel ?? "Logging in..."}>
      <Spinner/>
      <Heading variant="h3" style={{marginTop: tokens.spacing.l}}>
        {props.message ??"Loading..."}
      </Heading>
    </Modal>
  );
}

export default LoadingModal;